<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" width="100vw" flat>
            <v-row>
                <v-col cols="12" class="align-center justify-center">
                    <v-card :loading="loadingData">
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-card-title class="primary text-h6 grey lighten-2">
                                <span class="headline white--text">{{ formTitle }}</span>
                            </v-card-title>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0">
                                    <v-col cols="12">
                                        <v-text-field
                                            dense
                                            v-model="editedItem.title"
                                            label="Título"
                                            :rules="textRules"
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters align="center" justify="center">
                                            <v-img
                                                :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                max-height="250"
                                                max-width="auto"
                                                :aspect-ratio="1"
                                                class="white--text align-center justify-center elevation-2">
                                            </v-img>
                                        </v-row>
                                        <v-row no-gutters align="center" justify="center">
                                            <v-file-input
                                                v-model="imageFile"
                                                accept="image/png, image/jpeg, image/bmp"
                                                show-size
                                                clearable
                                                :rules="imageRules"
                                                label="Cargar/Cambiar imágen"
                                                placeholder="Archivo de imágen"
                                                prepend-icon="mdi-camera"
                                                @change="changePreviewImage()">
                                            </v-file-input>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card v-if="hasData"
                                                class="fill-height container--fluid" width="100vw"
                                                flat outlined>
                                            <v-data-table
                                                :headers="headers"
                                                :items="contents"
                                                :items-per-page="-1"
                                                :loading="loadingData"
                                                item-key="id"
                                                sort-by="id"
                                                disable-sort
                                                no-data-text="No hay datos para mostrar..."
                                                loading-text="Cargando los datos, por favor espere!"
                                                class="elevation-1"
                                                :footer-props="{
                                                      showFirstLastPage: false,
                                                      disableItemsPerPage: false,
                                                      itemsPerPageAllText: 'Todas',
                                                      itemsPerPageText: 'Filas por página:'
                                                    }">
                                                <template v-slot:top>
                                                    <v-toolbar flat class="align-center">
                                                        <v-toolbar-title
                                                            class="text-subtitle-1 text-md-h6 font-weight-light">Items Contenido
                                                        </v-toolbar-title>
                                                        <v-divider
                                                            class="mx-4"
                                                            inset
                                                            vertical
                                                        ></v-divider>
                                                        <v-spacer></v-spacer>
                                                        <v-dialog
                                                            :fullscreen="$vuetify.breakpoint.smAndDown"
                                                            v-model="dialog"
                                                            max-width="500px"
                                                            persistent>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    color="blue darken-1"
                                                                    dark
                                                                    small
                                                                    depressed
                                                                    elevation="0"
                                                                    class="mb-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="newItemContent()">
                                                                    Adicionar
                                                                </v-btn>
                                                            </template>
                                                            <v-card>
                                                                <v-form
                                                                    ref="form2"
                                                                    v-model="validForm"
                                                                    lazy-validation>
                                                                    <v-card-title class="text-h6 grey lighten-2">
                                                                        <span class="headline">Editar Elemento</span>
                                                                    </v-card-title>
                                                                    <v-divider></v-divider>
                                                                    <v-card-text>
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col cols="12">
                                                                                    <v-text-field
                                                                                        dense
                                                                                        v-model="editedItemContent.title"
                                                                                        label="Título"
                                                                                    ></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="12">
                                                                                    <v-textarea
                                                                                        dense
                                                                                        v-model="editedItemContent.content"
                                                                                        label="Contenido"
                                                                                        no-resize
                                                                                        rows="3"
                                                                                        counter>
                                                                                    </v-textarea>
                                                                                </v-col>
                                                                                <v-col cols="12">
                                                                                    <v-row no-gutters align="start"
                                                                                           justify="center">
                                                                                        <v-img
                                                                                            :src="imagePreviewContent != null ? imagePreviewContent : require('@/assets/images/no-image.jpg')"
                                                                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                                            max-height="150"
                                                                                            max-width="250"
                                                                                            :aspect-ratio="1"
                                                                                            class="white--text align-center justify-center elevation-2">
                                                                                        </v-img>
                                                                                    </v-row>
                                                                                    <v-row no-gutters align="center"
                                                                                           justify="center">
                                                                                        <v-file-input
                                                                                            v-model="imageFileContent"
                                                                                            accept="image/png, image/jpeg, image/bmp"
                                                                                            show-size
                                                                                            clearable
                                                                                            :rules="imageRules"
                                                                                            label="Cargar/Cambiar imágen"
                                                                                            placeholder="Archivo de imágen"
                                                                                            prepend-icon="mdi-camera"
                                                                                            @change="changePreviewImageContent()">
                                                                                        </v-file-input>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                    <v-card-actions>
                                                                        <v-spacer></v-spacer>
                                                                        <v-btn color="primary"
                                                                               text
                                                                               @click="closeDialog()">
                                                                            Cancelar
                                                                        </v-btn>
                                                                        <v-btn :disabled="!validForm"
                                                                               color="primary"
                                                                               text
                                                                               submit
                                                                               @click="saveDataContent()">
                                                                            Guardar
                                                                        </v-btn>
                                                                    </v-card-actions>
                                                                </v-form>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                                            <v-card>
                                                                <v-card-title class="text-h6 headline">
                                                                    Esta seguro que desea eliminar este elemento?
                                                                </v-card-title>
                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="blue darken-1" text
                                                                           @click="closeDelete()">Cancelar
                                                                    </v-btn>
                                                                    <v-btn color="blue darken-1" text
                                                                           @click="deleteItemConfirm()">Proceder
                                                                    </v-btn>
                                                                    <v-spacer></v-spacer>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                        <v-dialog
                                                            v-model="dialogError"
                                                            persistent
                                                            max-width="500">
                                                            <v-card>
                                                                <v-card-title class="text-h6 primary lighten-2">
                                                                    <span class="headline">Error de Validación</span>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <div class="text-h6 pa-12">{{ messageError }}!</div>
                                                                </v-card-text>
                                                                <v-card-actions class="justify-center">
                                                                    <v-btn text @click.stop="closeDialogError">
                                                                        Cerrar
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:item.actions="{ item }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="mr-2"
                                                                @click="editItemContent(item)">
                                                                mdi-pencil
                                                            </v-icon>
                                                        </template>
                                                        <span>Editar</span>
                                                    </v-tooltip>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="mr-2"
                                                                @click="deleteItem(item)">
                                                                mdi-delete
                                                            </v-icon>
                                                        </template>
                                                        <span>Eliminar</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" color="primary" @click="saveData">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs"
                       icon
                       @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import developPageService from '@/providers/DevelopPageService';
    import developContentPageService from '@/providers/DevelopContentPageService';
    
    export default {
        name: "DevelopModuleComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            validForm: true,
            loadingData: false,
            imageFile: null,
            imageFileContent: null,
            imagePreview: null,
            imagePreviewContent: null,
            selectedImageIndex: -1,
            dialog: false,
            dialogError: false,
            messageError: '',
            dialogDelete: false,
            contents: [],
            hasData: false,
            headers: [
                {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
                {
                    text: 'Titulo',
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },
                {
                    text: 'Contenido',
                    value: 'content',
                },
                {text: 'Acciones', value: 'actions', width: 100, sortable: false},
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            imageRules: [
                value => !value || value.size < 2000000 || 'El tamaño de la imagen no debe de sobrepasar los 2 MegaBytes!'
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                title: "",
                sub_title: "",
                image_url: "",
                content_pages: []
            },
            defaultItem: {
                id: 0,
                title: "",
                sub_title: "",
                image_url: "",
                content_pages: []
            },
            editedIndexContent: -1,
            editedItemContent: {
                id: 0,
                title: "",
                content: "",
                image_url: "",
                develop_id: 0,
            },
            defaultItemContent: {
                id: 0,
                title: "",
                content: "",
                image_url: "",
                develop_id: 0,
            }
        }),
        computed: {
            formTitle() {
                return "Desarrollo Inmobiliario";
            }
        },
        methods: {
            getDevelop() {
                this.loadingData = true;
                developPageService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.editedItem = record.value;
                        this.editedIndex = this.editedItem.id;
                        this.hasData = true;
                        if (this.editedItem.image_url !== null) {
                            this.imagePreview = this.editedItem.image_url;
                        } else {
                            this.imagePreview = null;
                        }
                        this.imageFile = null;
                        this.contents = this.editedItem.content_pages;
                    } else {
                        this.hasData = false;
                        this.editedItem = this.defaultItem;
                        this.contents = [];
                        this.newItem();
                    }
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.imagePreview = null;
                this.imageFile = null;
                this.valid = false;
            },
            saveData() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        developPageService.updateRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                developPageService.getViewData();
                                this.getDevelop();
                            }
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        developPageService.addRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                developPageService.getViewData();
                                this.getDevelop();
                            }
                        });
                    }
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFile.size > 2000000) {
                        // this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de la sección cargada con éxito...';
                    this.snackBar = true;
                }
            },
            ////////////////////////////////////////////////
            // Procesamiento de los Elementos de Contenidos
            getContents() {
                this.loadingData = true;
                developPageService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.editedItem = record.value;
                        this.editedIndex = this.editedItem.id;
                        this.contents = this.editedItem.content_pages;
                        this.hasData = true;
                    }
                    this.loadingData = false;
                });
            },
            newItemContent() {
                this.editedItemContent = Object.assign({}, this.defaultItemContent);
                this.editedItemContent.develop_id = this.editedItem.id;
                this.editedIndexContent = -1;
                this.imagePreviewContent = null;
                this.imageFileContent = null;
                this.selectedImageIndex = -1;
                this.validForm = true;
            },
            editItemContent(item) {
                this.editedIndexContent = this.contents.indexOf(item);
                this.editedItemContent = Object.assign({}, item);
                this.editedItemContent.develop_id = this.editedItem.id;
                this.imagePreviewContent = null;
                this.selectedImageIndex = -1;
                if (this.editedItemContent.image_url !== null) {
                    this.imagePreviewContent = this.editedItemContent.image_url;
                    this.selectedImageIndex = 0;
                } else {
                    this.imagePreviewContent = null;
                }
                this.imageFileContent = null;
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndexContent = this.contents.indexOf(item);
                this.editedItemContent = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                developContentPageService.deleteRecord(this.editedItemContent.id).then(
                    response => {
                        if (response.success) {
                            this.contents.splice(this.editedIndexContent, 1);
                            this.snackText = 'Contenido eliminada con éxito.';
                        } else {
                            this.snackText = 'Un error impidió eliminar el registro!';
                        }
                    }
                );
                this.closeDelete();
                this.snackBar = true;
            },
            closeDialog() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItemContent = Object.assign({}, this.editedItemContent);
                    this.editedIndexContent = -1;
                    this.imagePreviewContent = null;
                    this.selectedImageIndex = -1;
                });
            },
            closeDelete() {
                this.$nextTick(() => {
                    this.editedItemContent = Object.assign({}, this.editedItemContent);
                    this.editedIndexContent = -1;
                });
                this.dialogDelete = false
            },
            saveDataContent() {
                if (this.imagePreviewContent == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imágen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form2.validate();
                if (this.$refs.form2.validate(true)) {
                    if (this.editedIndexContent > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.contents[this.editedIndexContent], this.editedItemContent);
                        developContentPageService.updateRecord(this.editedItemContent, this.imageFileContent).then(
                            response => {
                                if (response.success) {
                                    this.getContents();
                                    // this.contents = this.editedIndexContent.contents;
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.contents.push(this.editedItemContent);
                        developContentPageService.addRecord(this.editedItemContent, this.imageFileContent).then(
                            response => {
                                if (response.success) {
                                    this.getContents();
                                    // this.contents = this.editedIndexContent.contents;
                                }
                            }
                        );
                    }
                    this.closeDialog();
                    this.snackText = 'Contenido guardado con éxito.';
                    this.snackBar = true;
                }
            },
            closeDialogError() {
                this.dialogError = false;
                this.messageError = '';
            },
            changePreviewImageContent() {
                if (this.imageFileContent) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFileContent.size > 2000000) {
                        // this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreviewContent = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFileContent);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de perfil cargada con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.editedItem = this.defaultItem;
            this.getDevelop();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>