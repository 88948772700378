<template>
    <develop-module-component/>
</template>

<script>
    import DevelopModuleComponent from "@/components/admin/DevelopModuleComponent";

    export default {
        name: "DevelopModule",
        title: "Desarrollo Inmobiliarios | Private",
        components: { DevelopModuleComponent }
    }
</script>

<style scoped>

</style>